import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthClientService } from 'src/app/@Services/@client-website/authclient.service';

@Injectable()
export class AuthWebsiteInterceptor implements HttpInterceptor {
  constructor(private _authClientService: AuthClientService,) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
    console.log('_____ interceptor in client website _____');
    
    const logindata: any = localStorage.getItem('websiteUser');
    const websiteUser = JSON.parse(logindata);
    
    let cookie: any = {};
    document.cookie.split(';').forEach((el) => {
      let [key, value] = el.split('=');
      cookie[key.trim()] = value;
    });

    if (!websiteUser) {
      return next.handle(request);
    } else {
      const modifyReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${websiteUser._token}`,
        },
      });
      return next.handle(modifyReq).pipe( 
        tap({
          error: (err) => {
            console.log(err,' <--Error');
            if(err.status == 401){ 
              this._authClientService.setNewAccess_token(websiteUser._retoken);
            };
          },
        })
      );;
    }
  }
}
