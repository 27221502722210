import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MaterialModule } from './@Shared/modules/material.module';
import { HeaderComponent } from './@Shared/header/header.component';
import {FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthWebsiteInterceptor } from './@Modules/@website/@auth/auth-material/auth-website.interceptor';
import { AgGridModule } from 'ag-grid-angular';
import { NgxOrgChartModule } from '@fdjlss/ngx-org-chart';
import { SurveyAttemptByUserComponent } from './@Free-pages/survey-attempt-by-user/survey-attempt-by-user.component';
import { SurveyModule } from 'survey-angular-ui';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SurveyAttemptByUserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FlexLayoutModule,
    // MatDialogModule,
    AgGridModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxOrgChartModule,
    SurveyModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass:AuthWebsiteInterceptor ,  multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
