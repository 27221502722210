export const ROLE:Role = {
    All:'All',
    Admin: 'Admin',
    HR: 'HR',
    Approver: 'Approver',
    Excutive: 'Excutive',
    Auditor: 'Auditor',
    Assurance_Partner: 'Assurance Partner',
    Operation: 'Operation',
    Executive: 'Executive',
    Finance: 'Finance',
  };

   interface Role {
    All:string,
    Admin:string,
    HR: string,
    Approver: string,
    Excutive: string,
    Auditor: string,
    Assurance_Partner: string,
    Operation: string,
    Executive: string,
    Finance: string,
  };
  
  Object.freeze(ROLE);