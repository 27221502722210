import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  sharedInfo: any = {}
  sharedInfoBehavior: BehaviorSubject<number>;
  constructor() {
    this.sharedInfoBehavior = new BehaviorSubject(this.sharedInfo);
  }

  sharedData(info: any) {
    this.sharedInfoBehavior.next(info);
  };

  getLoginStatus(){
    return this.sharedInfoBehavior;
  }
}
