import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
const baseUrl = environment.baseUrl;

let apiRoutes = {
  createSurveyQuestion: () => `${baseUrl}/client-admin/create-survey-question/`,
  getAssessmentDetailByAssessment: (assessment_id: number) => `${baseUrl}/client-admin/client-project-detail/?assessment_id=${assessment_id}`,
  getQuestionsSet: () => `${baseUrl}/client-admin/get-survey-question-set/`,
  surveyAssignToUser: () => `${baseUrl}/client-admin/survey-assign-to-user/`,
  getAllAssignSurvey: () => `${baseUrl}/client-admin/survey-assign-to-user/`,
  getAllAssignSurvey1: () => `${baseUrl}/client-admin/attempt-survey-questions/`,
  getSingleAssignSurveyByUser: (ids:any) => `${baseUrl}/client-admin/attempt-survey-questions/?survey_id=${ids.survey_id}&assign_id=${ids.assign_id}`,
  attemptSurveyQuestions: () => `${baseUrl}/client-admin/attempt-survey-questions/`,
  getSurveyById: (uuid:any) => `${baseUrl}/client-admin/attempt-survey-questions/${uuid}`,

  // added by vinay
  survey2:{
    createSurveyQuestion: () => `${baseUrl}/client-admin/create-survey-question/`,
    surveyAssignToUser: () => `${baseUrl}/client-admin/survey-assign-user/`,
    getAllAssignSurvey: () => `${baseUrl}/client-admin/survey-assign-user/`,
    getSingleSurveyDetail: (uuid:any) => `${baseUrl}/client-admin/get-survey-details/?uuid=${uuid}`,
    submitSurveyByUser: (uuid:any) => `${baseUrl}/client-admin/survey-attempt-answer/?uuid=${uuid}`,
    getSingleSurvey_admin: (uuid:any) => `${baseUrl}/client-admin/get-question-answer/?uuid=${uuid}`,
    completeSurvey_admin: () => `${baseUrl}/client-admin/complete-assign-survey/`,
    getAllQuestionOfSurvey: (id:any) => `${baseUrl}/client-admin/all-survey-questions/?survey_id=${id}`,
  }

}
@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(private httpClient: HttpClient) { }

  createSurvey(data: any) {
    return this.httpClient.post(apiRoutes.createSurveyQuestion(), data);
  };

  getSurvey() {
    return this.httpClient.get<any>(apiRoutes.createSurveyQuestion());
  };

  getQuestionsSet() {
    return this.httpClient.get<any>(apiRoutes.getQuestionsSet());
  };

  surveyAssignToUser(data: any) {
    console.log('data******', data.assign_to_user)
    const formData:FormData = new FormData();

    console.log('data for api call', formData)
    return this.httpClient.post(apiRoutes.surveyAssignToUser(), formData);
  };
  getAllAssignSurvey() {
    return this.httpClient.get(apiRoutes.getAllAssignSurvey());
  };
  getAllAssignSurvey1() {
    return this.httpClient.get(apiRoutes.getAllAssignSurvey1());
  };

  getSingleAssignSurveyByUser(ids:any) {
    return this.httpClient.get(apiRoutes.getSingleAssignSurveyByUser(ids));
  };

  attemptSurveyQuestions(data:any) {
    return this.httpClient.post(apiRoutes.attemptSurveyQuestions(), data);
  };

  getSurveyById(uuid: any){

    return this.httpClient.get(apiRoutes.getSurveyById(uuid));
  };



  // added by vinay
  createSurvey2(data:any){
    return this.httpClient.post(apiRoutes.survey2.createSurveyQuestion(), data);
  };

  surveyAssignToUser2(data:any){
    const formData:FormData = new FormData();
    for(let key in data){
      if(key == 'assign_to'){
        if(typeof data[key] == 'string'){
          formData.append(key, data[key]);
        }else{
          const file:File = data[key];
          formData.append(key, file, file.name);
        }
      }else{
        formData.append(key, data[key]);
      }
    };
    return this.httpClient.post(apiRoutes.survey2.surveyAssignToUser(), formData);
  };
  getAllAssignSurvey2() {
    return this.httpClient.get(apiRoutes.survey2.getAllAssignSurvey());
  };

  getSingleSurveyDetail(uuid:any) {
    return this.httpClient.get(apiRoutes.survey2.getSingleSurveyDetail(uuid));
  };
  submitSurveyByUser(uuid:any,data:any) {
    return this.httpClient.post(apiRoutes.survey2.submitSurveyByUser(uuid),data);
  };
  getSingleSurvey_admin(id:any) {
    return this.httpClient.get(apiRoutes.survey2.getSingleSurvey_admin(id));
  };
  completeSurvey_admin(data:any) {
    return this.httpClient.post(apiRoutes.survey2.completeSurvey_admin(),data);
  };
  getAllQuestionOfSurvey(id:any) {
    return this.httpClient.get(apiRoutes.survey2.getAllQuestionOfSurvey(id));
  };
}
