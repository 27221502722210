import { SurveyService } from './../../@Services/@client-website/survey.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Model } from 'survey-core';
import * as SurveyTheme from "survey-core/themes";

@Component({
  selector: 'app-survey-attempt-by-user',
  templateUrl: './survey-attempt-by-user.component.html',
  styleUrls: ['./survey-attempt-by-user.component.css']
})
export class SurveyAttemptByUserComponent implements OnInit {
  surveyModel: Model;
  singleSurveyDetails:any;
  uuid:any;
  showSurvey:boolean = false;
  surveyStatusText:any = 'Survey Not Found';
  constructor(private Router:Router, private Route:ActivatedRoute, private SurveyService:SurveyService, private toaster:ToastrService) {

  };

  ngOnInit(): void {
    const uuid = this.Route.snapshot.paramMap.get('id');
    if(uuid){
      this.uuid = uuid;
      this.getSingleSurvey(uuid);
    };
  };

  completeSurvey(){
    this.surveyModel.onComplete.add((sender, options)=>{
      this.surveyModel.showCompletedPage = false;
      this.submitSurvey(this.uuid, sender.getStructuredData(), options);
    });
  };

  getSingleSurvey(uuid:any){
    this.SurveyService.getSingleSurveyDetail(uuid).subscribe(
      (res:any)=>{
        console.log(res);
        this.singleSurveyDetails = res.data[0];
        let surveydData:any = res.data[0];
        delete surveydData.survey_id;
        console.log(surveydData);

        if(surveydData.status == 'not-started'){
          this.showSurvey = true;
          const survey = new Model(surveydData);
          this.surveyModel = survey;
          survey.applyTheme(SurveyTheme.DefaultLight);
          this.completeSurvey();
        }else{
          this.showSurvey = false;
          if(surveydData.status == 'completed'){
            this.surveyStatusText = 'Your survey is already Complete';
          }else if(surveydData.status == 'expired'){
            this.surveyStatusText = 'Your survey is already Expired';
          };
          
        };
      },(err)=>{
        this.showSurvey = false;
        console.log(err);
      }
    )
  };

  submitSurvey(uuid:any, data:any, options:any){
    this.SurveyService.submitSurveyByUser(uuid,data).subscribe(
      (res:any)=>{
        console.log(res);
        options.showSaveSuccess('Your Survey is complete');
        this.toaster.success('Your survey is complete');
        this.surveyModel.showCompletedPage = true;
        this.surveyModel.showCompleteButton = false;
      },(err:any)=>{
        console.log(err);
      }
    )
  }

}
