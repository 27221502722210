import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthWebsiteGuard } from './@Modules/@website/@auth/auth-material/auth-website.guard';
import { SurveyAttemptByUserComponent } from './@Free-pages/survey-attempt-by-user/survey-attempt-by-user.component';

const routes: Routes = [
  {path:'', redirectTo:'website', pathMatch:'full'},
  { path: 'website',loadChildren: () =>import('./@Modules/@website/website-home.module').then((m) => m.WebsiteHomeModule)},
  { path: 'client-admin',canActivate: [AuthWebsiteGuard], loadChildren: () =>import('./@Modules/@client-admin/client-admin-home.module').then((m) => m.ClientAdminHomeModule)},
  { path: 'compare', loadChildren: () => import('./@Modules/@website/compare/compare.module').then(m => m.CompareModule) },
  { path: 'survey-attempt/:id', component:SurveyAttemptByUserComponent,title:'ikoo survey'},
  {path:'**',redirectTo:'website',pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
