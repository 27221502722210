
<div fxLayout="row">
  <div fxFlex="100%">
    <div fxLayout="column">
      <div fxLayout="column" class="fixed-header">
        <div fxLayout="row" fxLayoutAlign="center" class="top-nav">
          <div fxFlex="50%">
            <img style="cursor: pointer;"
              src="../../../assets/images/ikoo-new-logo.png" alt="" />
          </div>
          <div fxFlex="50%" fxLayoutAlign="end">
            <div fxLayout="row" fxLayoutGap="10px">
              <button [matMenuTriggerFor]="languageMenu" mat-icon-button>
                <mat-icon>language</mat-icon>
              </button>
              <mat-menu #languageMenu="matMenu">
                <button mat-menu-item>English</button>
                <button mat-menu-item>Spanish</button>
              </mat-menu>
              <img src="../../../assets/images/Aspire-Impact-Logo.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <survey [model]="surveyModel" *ngIf="showSurvey"></survey>
        <p class="notFound" *ngIf="!showSurvey">{{surveyStatusText}}</p>
      </div>
    </div>
  </div>
</div>

